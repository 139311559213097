.toolbar {
    position: relative;
    width: 100%;
    background-color: #696969;
    height: 60px;
}

.toolbar_nav {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_logo a {
  color: white;
  text-decoration: none;
}

.navspace {
  flex: 1;
}
.toolbar_menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_menu li {
  padding: 0 0.5rem;
}

.toolbar_menu a {
  color: white;
  text-decoration: none;
}

.toolbar_menu a:hover,
.toolbar_menu a:active {
  color: orange;
}
@media (max-width: 768px) {
  .toolbar_menu{
    display: none;
  }
}
@media (min-width: 769px) {
  .toolbar-toggle-button{
    display: none;
  }
}
