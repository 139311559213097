.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  z-index: 200;
  min-width: 200px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.side-drawer a {
  color: #845511;
  text-decoration: none;
}

.side-drawer li {
  margin: .5rem 0;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: orange;
}
.side-drawer.open {
  transform: translateX(0);
}
@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
}
