
.jumbotron_fullWArt {
  background: rgba(0,0,0,0.5);
  padding: 5px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.5);
  border-color: rgba(0,0,0,0.05);
  border-style: solid;
  margin: 10px;

}
.fullWArtImg {
  display: flex;
  padding: 10px;
  top: 0;
  left: 0;
  float:left;
  max-height: 30%;
  max-width: 30%;

}

.fullWArtTitle {
  padding: 10px;
  text-align: center;
  font-size: 24px;
}

.fullWArtBody {
  padding: 10px;
  align-self: auto;
  right: 0;

}
