.container_land {
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;
}

.jumbotron_land {
  text-align: center;
  background: rgba(0,0,0,0.5);
  padding: 5px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.5);
  border-color: rgba(0,0,0,0.05);
  border-style: solid;
}
